import React, { useContext } from 'react'

import { ColsContext } from 'src/hooks/cols/context'

import styles from './body_styling.module.scss'

const Prices = () => {
	const { cols } = useContext(ColsContext)

	const narrow = cols === 'line_sp' || cols === 'line_06'

	return (
		<div className={styles.prices}>
			<div className={styles.head}>
				{narrow && <p>13:00〜22:00（最終予約20:45）年中無休で1日1回完全予約制で自由に使いたい放題。</p>}
				<p>空いた時間にサッとエステだけでも、時間に余裕があるときはエステとフィットネスジムで欲張ってもOK！</p>
			</div>

			<table className={styles.table1}>
				<thead>
					<tr>
						{narrow ? <th>プラン</th> : <th>Body Stylingライトプラン</th>}

						<th>時間</th>
						<th>利用可能時間</th>
						<th>月額利用料</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						{narrow ? (
							<td className={styles.plan}>
								Body Styling
								<wbr />
								ライトプラン
							</td>
						) : (
							<td className={styles.text}>
								13:00〜22:00（最終予約20:45）年中無休で1日1回完全予約制で自由に使いたい放題。
							</td>
						)}

						<td className={styles.time}>
							<span>40</span>分 / <span>75</span>分
						</td>
						<td className={styles.available}>
							<span>All Day</span>
						</td>
						<td className={styles.charge}>
							<span>¥5,500</span> / 月
						</td>
					</tr>
				</tbody>
			</table>

			<table className={styles.table2}>
				<thead className={styles.pcOnly}>
					<tr>
						{narrow ? <th>プラン</th> : <th>Body Styling 定額制プラン</th>}

						<th>時間</th>
						<th>利用可能時間</th>
						<th>月額利用料</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						{narrow ? (
							<td className={styles.plan} rowSpan={2}>
								Body Styling
								<wbr />
								定額制プラン
							</td>
						) : (
							<td className={styles.text} rowSpan={2}>
								13:00〜22:00（最終予約20:45）年中無休で1日1回完全予約制で自由に使いたい放題。
							</td>
						)}

						<td className={styles.time}>
							<span>45</span>分
						</td>
						<td className={styles.available}>
							<span>All Day</span>
						</td>
						<td className={styles.charge}>
							<span>¥8,778</span> / 月
						</td>
					</tr>
					<tr>
						<td className={styles.time}>
							<span>75</span>分
						</td>
						<td className={styles.available}>
							<span>All Day</span>
						</td>
						<td className={styles.charge}>
							<span>¥10,978</span> / 月
						</td>
					</tr>
				</tbody>
			</table>

			<div className={styles.caption}>
				<p>※1 表記はすべて税込での金額になります。</p>
			</div>
		</div>
	)
}

export default Prices
